































































































































































































































































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Page404 extends Vue {
  goBack() {
    window.location.replace("/");
  }
}
